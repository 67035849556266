div.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--color-background);
}

div.header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    padding: 0.5rem;
}

div.header-nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
}

nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 1rem;
}

nav > a {
    text-decoration: none;
    color: var(--color-primary);
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 0.8rem;
}

nav > a:hover,
nav > a:active {
    color: var(--color-background);
    background-color: var(--color-secondary);
}
