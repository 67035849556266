.status-container {
    width: 100%;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    height: 5vh;
    border-top: 1px solid var(--color-primary);
    background-color: var(--color-background);
}

.cyoa-container {
    padding-bottom: 5vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
