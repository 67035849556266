@import url('https://cdnjs.cloudflare.com/ajax/libs/pretendard/1.3.9/variable/pretendardvariable-dynamic-subset.min.css');

:root {
    --color-primary: #222831;
    --color-secondary: #393e46;
    --color-highlight: #00adb5;
    --color-background: #eeeeee;
}

html,
body {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    font-family:
        'Pretendard Variable',
        Pretendard,
        -apple-system,
        BlinkMacSystemFont,
        system-ui,
        Roboto,
        'Helvetica Neue',
        'Segoe UI',
        'Apple SD Gothic Neo',
        'Noto Sans KR',
        'Malgun Gothic',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* 일반적인 방법 */
}

code {
    font-family: 'D2Coding', source-code-pro, 'Pretendard Variable', Pretendard, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
    color: var(--color-highlight);
}

a.hover,
a.active {
    color: var(--color-secondary);
    text-decoration: underline;
}
